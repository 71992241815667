export default {
  name: 'BmUsersComponent',
  data () {
    return {
      tab: null,
      items: [
        'Usuario', 'Roles'
      ]
    }
  },
  watch: {
    tab (newVal, oldVal) {
      switch (newVal) {
        case 0:
          break
        case 1:
          break
        default:
          break
      }
    }
  },
  methods: {
    initialize () {},
    goToRouteFirst () {
      this.$router.push({ path: '/admin/users' }).catch(() => {})
    }
  }
}
